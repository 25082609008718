import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import videoSpace from "../images/video/afterAnimation_liz-del-mar_v1.mp4"

import showroomStyles from "./showroom.module.css"

export default ({ props }) => {
  return (
    <div className={showroomStyles.imgContainer}>
      <video
        className={showroomStyles.ImgShow}
        autoPlay={true}
        playsinline
        loop
        muted
        controlsList="nodownload"
      >
        <source src={videoSpace} type="video/mp4" />
      </video>
    </div>
  )
}
