import React, { Component } from "react"
import styleShow from "./styleShowWork.module.css"
import shortid from "shortid"
import Img from "gatsby-image"
import bottomBar from "../../../images/footer.svg"

import { withTranslation } from "react-i18next"

class workWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      changeL: props.changeLan,
      node: props.work,
      duperre: props.ecole === "duperre",
      stienne: props.ecole === "stienne",
      ensaama: props.ecole === "ensaama",
      closeMe: props.close,
      idx_img: props.idxImg,
      from: 0,
      changingImg: false,
    }
    this.getRandomKey = this.getRandomKey.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onPrev = this.onPrev.bind(this)
    this.handleKey = this.handleKey.bind(this)
    this.keys1 = { key: 234 }
    this.keys2 = { key: 654 }
    this.keys3 = { key: 678 }
  }

  handleKey = e => {
    if (e.keyCode === 27) {
      this.state.closeMe()
    }
    if (e.keyCode === 39) {
      this.onNext()
    }
    if (e.keyCode === 37) {
      this.onPrev()
    }
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  componentDidMount() {
    this.setState(() => ({
      _isMounted: true,
    }))
    document.addEventListener("keydown", this.handleKey)
    let refresh =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?work=" +
      this.state.node.id +
      "&img=" +
      this.state.idx_img
    if (this.state.duperre) {
      refresh += "&c=t"
    }
    if (this.state.stienne) {
      refresh += "&c=l"
    }
    if (this.state.ensaama) {
      refresh += "&c=y"
    }
    window.history.pushState({ path: refresh }, "", refresh)
    if (this.state.changeL.change) {
      this.state.i18n.changeLanguage(this.state.changeL.lan)
    } else {
      if (this.state.duperre || this.state.stienne || this.state.ensaama) {
        this.state.i18n.changeLanguage("fr")
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKey)
    let refresh =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname

    if (this.state.duperre) {
      refresh += "?c=t"
    }
    if (this.state.stienne) {
      refresh += "?c=l"
    }
    if (this.state.ensaama) {
      refresh += "?c=y"
    }
    window.history.pushState({ path: refresh }, "", refresh)
  }

  onNext() {
    this.setState(prevState => ({
      from: 1,
      changingImg: true,
      idx_img: (prevState.idx_img + 1) % this.state.node.images.length,
    }))
  }

  onPrev() {
    var newidx = this.state.idx_img
    if (newidx === 0) {
      newidx = this.state.node.images.length - 1
    } else {
      newidx--
    }
    this.setState(prevState => ({
      from: 2,
      changingImg: true,
      idx_img: newidx,
    }))
  }

  render() {
    var shortTitle =
      this.state.node[this.state.i18n.languages[0] + "Title"].length < 25

    var description = ""
    for (const [index, value] of this.state.node[
      this.state.i18n.languages[0] + "Description"
    ].json.content[0].content.entries()) {
      description += value.value
    }

    if (this.state.changingImg) {
      this.keys1.key = this.getRandomKey()
      this.keys2.key = this.getRandomKey()
      this.keys3.key = this.getRandomKey()

      this.state.changingImg = false
      let refresh =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?work=" +
        this.state.node.id +
        "&img=" +
        this.state.idx_img
      if (this.state.duperre) {
        refresh += "&c=t"
      }
      if (this.state.stienne) {
        refresh += "&c=l"
      }
      if (this.state.ensaama) {
        refresh += "&c=y"
      }
      window.history.pushState({ path: refresh }, "", refresh)
    }

    return (
      <div className={styleShow.Global}>
        <div className={styleShow.TopBar}></div>
        <img className={styleShow.BopBar} src={bottomBar}></img>
        <img
          className={styleShow.closeIcon}
          src="/static/44075f2983fb48e382ff817021af293d/ee604/close.png"
          onClick={this.state.closeMe}
        ></img>
        <img
          className={styleShow.IconHongos}
          src="/static/f24d1581103294e2ec5cba1ab11a43a7/ee604/hong.png"
        ></img>
        <div className={styleShow.topPart}>
          <div className={styleShow.leftPart}>
            <div
              className={
                shortTitle ? styleShow.titleShort : styleShow.titleLong
              }
            >
              <div
                className={
                  shortTitle
                    ? styleShow.titleTextShort
                    : styleShow.titleTextLong
                }
              >
                {this.state.node[this.state.i18n.languages[0] + "Title"]}
              </div>
            </div>
            <div className={styleShow.description}>
              <p className={styleShow.descriptionText}>{description}</p>
            </div>
          </div>
          <div className={styleShow.centerPart} onClick={this.onNext}>
            <div
              className={styleShow.ImgContOut}
              style={
                this.state.from !== 1
                  ? {
                      right: "100%",
                      display: "none",
                    }
                  : {}
              }
              id={this.state.from === 1 ? styleShow.leftfromcen : ""}
              {...this.keys1}
            >
              <div className={styleShow.ImgCont}>
                <Img
                  fluid={
                    this.state.node.images[
                      this.state.idx_img === 0
                        ? this.state.node.images.length - 1
                        : this.state.idx_img - 1
                    ].fluid
                  }
                  className={styleShow.Imag}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                  loading="eager"
                ></Img>
              </div>
            </div>

            <div
              className={styleShow.ImgContOut}
              id={
                this.state.from === 1
                  ? styleShow.rigCen
                  : this.state.from === 2
                  ? styleShow.lefCen
                  : ""
              }
              {...this.keys2}
            >
              <div className={styleShow.ImgCont}>
                <Img
                  fluid={this.state.node.images[this.state.idx_img].fluid}
                  className={styleShow.Imag}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                  loading="eager"
                ></Img>
              </div>
            </div>

            <div
              className={styleShow.ImgContOut}
              style={
                this.state.from !== 2
                  ? {
                      left: "100%",
                      display: "none",
                    }
                  : {}
              }
              id={this.state.from === 2 ? styleShow.rightfromcen : ""}
              {...this.keys3}
            >
              <div className={styleShow.ImgCont}>
                <Img
                  fluid={
                    this.state.node.images[
                      this.state.idx_img === 0
                        ? this.state.node.images.length - 1
                        : this.state.idx_img - 1
                    ].fluid
                  }
                  className={styleShow.Imag}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "50% 50%",
                  }}
                  loading="eager"
                ></Img>
              </div>
            </div>
          </div>
        </div>
        <div className={styleShow.bottomPart}>
          <div className={styleShow.MarginButtonLeft}></div>
          <div className={styleShow.ButtonContainer}>
            <div className={styleShow.controlButton}>
              <img
                className={styleShow.controlButtonPrev}
                src="/static/1788d464e359d82e2a66ae38af021f31/31406/next.png"
                onClick={this.onPrev}
              ></img>
              <div className={styleShow.JustSpace}></div>
              <img
                className={styleShow.controlButtonNext}
                src="/static/1788d464e359d82e2a66ae38af021f31/31406/next.png"
                onClick={this.onNext}
              ></img>
            </div>
          </div>
          <div className={styleShow.MarginButtonRight}></div>
        </div>
        {this.state.duperre ? (
          <img
            className={styleShow.DuperreImage}
            src="/static/d9cd98a7df5f8198398fc9b69e4f5281/ee604/hiDuperre.png"
          ></img>
        ) : (
          <div></div>
        )}
        {this.state.stienne ? (
          <img
            className={styleShow.DuperreImage}
            src="/static/2825131d3c1b2ccc07ad5b5d0f07ab4c/ee604/hiStienne.png"
          ></img>
        ) : (
          <div></div>
        )}
        {this.state.ensaama ? (
          <img
            className={styleShow.DuperreImage}
            src="/static/c9037162ed2b4715cdbdfc2fc41a4f4f/ee604/hiEnsaama.png"
          ></img>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}

export default withTranslation()(workWindow)
