import React, { Component } from "react"

import styleWork from "./styleWorkWindow.module.css"
import shortid from "shortid"
import { withTranslation } from "react-i18next"

import OpenWork from "./showWork"
import OpenWorkPhone from "./showWorkPhone"

class workWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      data: props.data,
      query: props.dataUrl,
      someActive: false,
      active: false,
      efecto: "slide-left",
      closing: false,
      _isMounted: false,
      changeLan: {
        lan: "",
        change: false,
      },
      ecole: "nada",
      width: 0,
      height: 0,
      node: {},
      imgShow: 0,
    }

    this.getQueryVariable = this.getQueryVariable.bind(this)
    this.getRandomKey = this.getRandomKey.bind(this)
    this.closeWork = this.closeWork.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  closeAnimationEnd = () => {
    if (this.state.closing) {
      this.setState(prevState => ({
        closing: false,
        active: false,
      }))
    }
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)
    this.updateWindowDimensions()
    this.setState(() => ({
      _isMounted: true,
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  getQueryVariable(variable) {
    if (this.state.query !== undefined) {
      var query = this.state.query.substring(1)
      var vars = query.split("&")
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=")
        if (pair[0] == variable) {
          return pair[1]
        }
      }
    }
    return false
  }

  closeWork() {
    if (this.state._isMounted) {
      this.setState(prevState => ({
        closing: true,
        query: "",
      }))
    }
  }

  render() {
    this.state.active = false

    for (const [
      index,
      value,
    ] of this.state.data.allContentfulWork.edges.entries()) {
      if (value.node.id === this.getQueryVariable("work")) {
        this.state.active = true
        this.state.node = value.node
        if (this.getQueryVariable("img")) {
          this.state.imgShow = parseInt(this.getQueryVariable("img"))
        }
        var ecole = this.getQueryVariable("c")
        if (ecole) {
          if (ecole === "t") {
            this.state.ecole = "duperre"
          }
          if (ecole === "l") {
            this.state.ecole = "stienne"
          }
        }
        var lan = this.getQueryVariable("l")
        if (lan) {
          this.state.changeLan.change = true
          this.state.changeLan.lan = lan
        }
        break
      }
    }

    return (
      <div>
        {this.state.active ? (
          this.state.width > 850 ? (
            <div
              className={styleWork.OpenWork}
              id={this.state.closing ? styleWork.closePc : styleWork.openingPc}
              onAnimationEnd={this.closeAnimationEnd}
            >
              <OpenWork
                changeLan={this.state.changeLan}
                ecole={this.state.ecole}
                work={this.state.node}
                close={this.closeWork}
                idxImg={this.state.imgShow}
              ></OpenWork>
            </div>
          ) : (
            <div onAnimationEnd={this.closeAnimationEnd}>
              <div
                className={styleWork.floatingCloseButton}
                id={
                  this.state.closing
                    ? styleWork.closingCloseButton
                    : styleWork.openingCloseButton
                }
                onClick={this.closeWork}
              >
                <img
                  className={styleWork.closeButton}
                  src="/static/8cba15cbf49bb7fe0db2d2922d2ff9f4/ee604/closePhone.png"
                ></img>
              </div>
              <div
                className={styleWork.OpenWorkPhone}
                id={
                  this.state.closing
                    ? styleWork.closePhone
                    : styleWork.openingPhone
                }
              >
                <OpenWorkPhone
                  footerImg={
                    this.state.width > 650
                      ? "/static/a916a83b428d506ea035f7e7f0a61b18/ee604/pastico_tablet_Liz_del_Mar.png"
                      : "/static/877e7e9fbc7744ed88f6b950c81e3fe9/ee604/footWorkPhone.png"
                  }
                  work={this.state.node}
                  ecole={this.state.ecole}
                  close={this.closeWork}
                  idxImg={this.state.imgShow}
                ></OpenWorkPhone>
              </div>
            </div>
          )
        ) : (
          <div />
        )}
      </div>
    )
  }
}

export default withTranslation()(workWindow)
