import React, { Component } from "react"
import styleShow from "./styleShowWorkPhone.module.css"
import shortid from "shortid"
import Img from "gatsby-image"
import { useSwipeable, Swipeable } from "react-swipeable"

import { withTranslation } from "react-i18next"

const swipeConf = {
  delta: 90, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: false, // track mouse input
  rotationAngle: 0, // set a rotation angle
}

class workWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      node: props.work,
      closeMe: props.close,
      idx_img: props.idxImg,
      from: 0,
      changingImg: false,
    }
    this.getRandomKey = this.getRandomKey.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onPrev = this.onPrev.bind(this)
    this.onSwipeClose = this.onSwipeClose.bind(this)
    this.swipping = this.swipping.bind(this)
    this.keys1 = { key: 234 }
    this.keys2 = { key: 654 }
    this.keys3 = { key: 678 }
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  componentDidMount() {
    // document.body.style.overflow = "hidden"
    let refresh =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?work=" +
      this.state.node.id +
      "&img=" +
      this.state.idx_img
    window.history.pushState({ path: refresh }, "", refresh)
    this.setState(() => ({
      _isMounted: true,
    }))
  }
  componentWillUnmount() {
    // document.body.style.overflow = "unset"
    let refresh =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname
    window.history.pushState({ path: refresh }, "", refresh)
  }

  swipping(data) {
    if (data.deltaX > 90) {
      this.onNext()
    } else {
      if (data.deltaX < -90) {
        this.onPrev()
      }
    }
  }

  onSwipeClose(data) {
    if (data.deltaX < -100) {
      this.state.closeMe()
    }
  }

  onNext() {
    this.setState(prevState => ({
      from: 1,
      changingImg: true,
      idx_img: (prevState.idx_img + 1) % this.state.node.images.length,
    }))
  }

  onPrev() {
    var newidx = this.state.idx_img
    if (newidx === 0) {
      newidx = this.state.node.images.length - 1
    } else {
      newidx--
    }
    this.setState(prevState => ({
      from: 2,
      changingImg: true,
      idx_img: newidx,
    }))
  }

  render() {
    var horizontal =
      this.state.node.images[this.state.idx_img].file.details.image.width >
      this.state.node.images[this.state.idx_img].file.details.image.height

    var shortTitle =
      this.state.node[this.state.i18n.languages[0] + "Title"].length < 25

    var description = ""
    for (const [index, value] of this.state.node[
      this.state.i18n.languages[0] + "Description"
    ].json.content[0].content.entries()) {
      description += value.value
    }

    if (this.state.changingImg) {
      this.keys1.key = this.getRandomKey()
      this.keys2.key = this.getRandomKey()
      this.keys3.key = this.getRandomKey()

      this.state.changingImg = false

      let refresh =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?work=" +
        this.state.node.id +
        "&img=" +
        this.state.idx_img
      window.history.pushState({ path: refresh }, "", refresh)
    }
    return (
      <div>
        <div className={styleShow.Global}>
          <div>
            <Swipeable
              // onSwipedLeft={this.onSwipeL}
              // onSwipedRight={this.onSwipeR}
              onSwiping={eventData => this.swipping(eventData)}
              {...swipeConf}
            >
              <div
                className={
                  horizontal ? styleShow.ImgContOutH : styleShow.ImgContOutV
                }
              >
                {/* {this.state.from === 1 ? ( */}
                {true ? (
                  <div
                    className={
                      horizontal ? styleShow.ImgContH : styleShow.ImgContV
                    }
                    style={
                      this.state.from !== 1
                        ? {
                            right: "100%",
                            display: "none",
                          }
                        : {}
                    }
                    id={this.state.from === 1 ? styleShow.leftfromcen : ""}
                    {...this.keys1}
                  >
                    <Img
                      fluid={
                        this.state.node.images[
                          this.state.idx_img === 0
                            ? this.state.node.images.length - 1
                            : this.state.idx_img - 1
                        ].fluid
                      }
                      className={styleShow.Imag}
                      imgStyle={{
                        objectFit: "contain",
                        objectPosition: "50% 50%",
                      }}
                      loading="eager"
                    ></Img>
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  className={
                    horizontal ? styleShow.ImgContH : styleShow.ImgContV
                  }
                  id={
                    this.state.from === 1
                      ? styleShow.cenfromright
                      : this.state.from === 2
                      ? styleShow.cenfromleft
                      : ""
                  }
                  {...this.keys2}
                >
                  <Img
                    fluid={this.state.node.images[this.state.idx_img].fluid}
                    className={styleShow.Imag}
                    imgStyle={{
                      objectFit: "contain",
                      objectPosition: "50% 50%",
                    }}
                    loading="eager"
                  ></Img>
                </div>
                {/* {this.state.from === 2 ? ( */}
                {true ? (
                  <div
                    className={
                      horizontal ? styleShow.ImgContH : styleShow.ImgContV
                    }
                    style={
                      this.state.from !== 2
                        ? {
                            left: "100%",
                            display: "none",
                          }
                        : {}
                    }
                    id={this.state.from === 2 ? styleShow.rightfromcen : ""}
                    {...this.keys3}
                  >
                    <Img
                      fluid={
                        this.state.node.images[
                          (this.state.idx_img + 1) %
                            this.state.node.images.length
                        ].fluid
                      }
                      className={styleShow.Imag}
                      imgStyle={{
                        objectFit: "contain",
                        objectPosition: "50% 50%",
                      }}
                      loading="eager"
                    ></Img>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </Swipeable>
            <Swipeable
              onSwiping={eventData => this.onSwipeClose(eventData)}
              {...swipeConf}
            >
              <div className={styleShow.closeButtonContainer}>
                <div className={styleShow.butCont}></div>
                <div className={styleShow.TitleContainer}>
                  <div
                    className={
                      shortTitle ? styleShow.titleShort : styleShow.titleLong
                    }
                  >
                    <div
                      className={
                        shortTitle
                          ? styleShow.titleTextShort
                          : styleShow.titleTextLong
                      }
                    >
                      {this.state.node[this.state.i18n.languages[0] + "Title"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styleShow.description}>
                <p className={styleShow.descriptionText}>{description}</p>
              </div>
              <div className={styleShow.footerWork}>
                <img
                  className={styleShow.footerImg}
                  src={this.props.footerImg}
                ></img>
              </div>
            </Swipeable>
            <img
              className={
                horizontal
                  ? styleShow.controlButtonPrevH
                  : styleShow.controlButtonPrevV
              }
              src="/static/b67bc1668f5161c0414d9ea821943be4/73f23/violet_arrow.png"
              onClick={this.onPrev}
            ></img>
            <img
              className={
                horizontal
                  ? styleShow.controlButtonNextH
                  : styleShow.controlButtonNextV
              }
              src="/static/b67bc1668f5161c0414d9ea821943be4/73f23/violet_arrow.png"
              onClick={this.onNext}
            ></img>
            <img
              className={styleShow.nubes}
              src="/static/4291a4ffac95345f5ede8c02bc617453/ee604/nubes_Liz_del_Mar.png"
            ></img>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(workWindow)
