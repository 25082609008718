import React, { Component } from "react"
import GetUrl from "../../../functions/getUrl_Img_Width"
import styleWork from "./styleWorkWindow.module.css"
import shortid from "shortid"
import Img from "gatsby-image"
import OpenWork from "./showWork"
import OpenWorkPhone from "./showWorkPhone"
import { Link, navigate } from "gatsby"

import { withTranslation } from "react-i18next"

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

class workWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      idx_img: 0,
      changeLan: {
        lan: "",
        change: false,
      },
      imgShow: props.idxImg,
      horizontal: true,
      node: props.work.node,
      timer: setTimeout(() => {
        this.changeIndImg()
      }, Math.floor(5000 + Math.random() * 3000)),
      lastImg: "",
      ecole: "nada",
      query: props.dataUrl,
      first: true,
      _isMounted: false,
      active: props.active,
      rotation: false,
      openWork: false,
      width: 0,
      height: 0,
      theposition: 0,
      lastPosition: 0,
      uptDim: false,
      closing: false,
      justClose: false,
      needScroll: false,
      someActive: props.someActive,
      widthImg: "600",
    }
    this.changeIndImg = this.changeIndImg.bind(this)
    this.getRandomKey = this.getRandomKey.bind(this)
    this.openWork = this.openWork.bind(this)
    this.closeWork = this.closeWork.bind(this)
    this.preventToDie = this.preventToDie.bind(this)
    this.getQueryVariable = this.getQueryVariable.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    // this.listenToScroll = this.listenToScroll.bind(this)
    this.targetElement = null
  }

  getRandomKey = () => {
    return shortid.generate()
  }

  preventToDie = () => {
    if (this.state.active) {
      enableBodyScroll(this.targetElement)

      this.state.justClose = true

      this.closeWork()
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions)
    window.addEventListener("popstate", this.preventToDie)
    this.updateWindowDimensions()
    this.targetElement = document.querySelector("workwork")
    this.setState(() => ({
      _isMounted: true,
    }))
  }

  getQueryVariable(variable) {
    if (this.state.query !== undefined) {
      var query = this.state.query.substring(1)
      var vars = query.split("&")
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=")
        if (pair[0] == variable) {
          return pair[1]
        }
      }
    }
    return false
  }

  openWork() {
    clearTimeout(this.state.timer)
    var timee = 60000
    disableBodyScroll(this.targetElement)
    window.history.pushState(null, "modalOpened")
    this.state.lastPosition = this.listenToScroll()
    if (this.state._isMounted) {
      this.setState(prevState => ({
        closing: false,
        active: true,
        rotation: true,
        timer: null,
      }))
    }
  }

  closeWork() {
    clearTimeout(this.state.timer)
    var timee = 5000
    enableBodyScroll(this.targetElement)

    if (this.state._isMounted) {
      this.setState(prevState => ({
        closing: true,
        rotation: true,
        timer: setTimeout(() => {
          this.changeIndImg()
        }, timee),
      }))
    }
  }

  changeIndImg = () => {
    clearTimeout(this.state.timer)
    var randomTime = Math.floor(5000 + Math.random() * 3000)
    if (this.state._isMounted) {
      this.setState(prevState => ({
        idx_img: (prevState.idx_img + 1) % this.state.node.images.length,
        timer: setTimeout(() => {
          this.changeIndImg()
        }, randomTime),
        lastImg: this.state.node.images[prevState.idx_img],
        first: false,
        rotation: false,
      }))
    }
  }

  closeAnimationEnd = () => {
    if (this.state.closing) {
      this.setState(prevState => ({
        closing: false,
        active: false,
      }))
    }
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height

    return winScroll
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer)
    clearAllBodyScrollLocks()
    window.removeEventListener("resize", this.updateWindowDimensions)
    window.removeEventListener("popstate", this.preventToDie)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    this.state.horizontal =
      this.state.node.images[this.state.idx_img].file.details.image.width >
      this.state.node.images[this.state.idx_img].file.details.image.height

    var imag

    if (
      this.state.node.images[this.state.idx_img].file.details.image.width >
      2.5 * this.state.node.images[this.state.idx_img].file.details.image.height
    ) {
      this.state.widthImg = 900
      console.log(this.state.node[this.state.i18n.languages[0] + "Title"])
    }

    if (!this.state.rotation) {
      imag = (
        <img
          src={
            this.state.first
              ? ""
              : GetUrl(this.state.lastImg.file.url, this.state.widthImg)
          }
          alt=""
          id={styleWork.outImg}
        ></img>
      )
    }

    if (this.state.justClose) {
      if (window.scrollY !== this.state.lastPosition) {
        window.scrollTo(0, this.state.lastPosition)
        this.state.justClose = false
      }
    }

    var ecole = this.getQueryVariable("c")
    if (ecole) {
      if (ecole === "t") {
        this.state.ecole = "duperre"
      }
      if (ecole === "l") {
        this.state.ecole = "stienne"
      }
      if (ecole === "y") {
        this.state.ecole = "ensaama"
      }
    }

    return (
      // <div>

      <div data-sal="slide-left">
        <div className={styleWork.oneWork} onClick={this.openWork}>
          <div className={styleWork.imageThing}>
            <div
              className={
                this.state.horizontal
                  ? styleWork.imgConatinerH
                  : styleWork.imgConatinerW
              }
              key={this.getRandomKey()}
            >
              <img
                src={GetUrl(
                  this.state.node.images[this.state.idx_img].file.url,
                  this.state.widthImg
                )}
                alt=""
                id={this.state.rotation ? " " : styleWork.inImg}
              ></img>
            </div>
            <div
              className={
                this.state.horizontal
                  ? styleWork.imgConatinerH
                  : styleWork.imgConatinerW
              }
              key={this.getRandomKey()}
            >
              {imag}
            </div>

            <div
              key={this.getRandomKey()}
              className={
                this.state.active && this.state.width > 850
                  ? styleWork.windowContainerRotate
                  : styleWork.windowContainer
              }
            >
              <img src={this.props.window}></img>
            </div>
            {this.state.width > 850 ? (
              <div
                className={styleWork.bichitoContainer}
                style={{
                  display: this.state.active ? "block" : "",
                }}
              >
                <img src={this.props.bichito}></img>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div
            className={styleWork.TextFoot}
            style={{ display: this.state.active ? "block" : "" }}
          >
            <h3 className={styleWork.textImg}>
              {this.state.node[this.state.i18n.languages[0] + "Title"]}
            </h3>
            <h3 className={styleWork.textTech}>
              {this.props.work.node[this.state.i18n.languages[0] + "Tech"]}
            </h3>
            <h3 className={styleWork.textYear}>{this.state.node.year}</h3>
          </div>
        </div>
        {this.state.active ? (
          this.state.width > 850 ? (
            <div
              className={styleWork.OpenWork}
              id={this.state.closing ? styleWork.closePc : styleWork.openingPc}
              onAnimationEnd={this.closeAnimationEnd}
            >
              <OpenWork
                changeLan={this.state.changeLan}
                ecole={this.state.ecole}
                work={this.state.node}
                close={this.closeWork}
                idxImg={this.state.imgShow}
              ></OpenWork>
            </div>
          ) : (
            <div onAnimationEnd={this.closeAnimationEnd}>
              <div
                className={styleWork.floatingCloseButton}
                id={
                  this.state.closing
                    ? styleWork.closingCloseButton
                    : styleWork.openingCloseButton
                }
                onClick={this.closeWork}
              >
                <img
                  className={styleWork.closeButton}
                  src="/static/8cba15cbf49bb7fe0db2d2922d2ff9f4/ee604/closePhone.png"
                ></img>
              </div>
              <div
                className={styleWork.OpenWorkPhone}
                id={
                  this.state.closing
                    ? styleWork.closePhone
                    : styleWork.openingPhone
                }
              >
                <OpenWorkPhone
                  ecole={this.state.ecole}
                  footerImg={
                    this.state.width > 650
                      ? "/static/a916a83b428d506ea035f7e7f0a61b18/ee604/pastico_tablet_Liz_del_Mar.png"
                      : "/static/877e7e9fbc7744ed88f6b950c81e3fe9/ee604/footWorkPhone.png"
                  }
                  work={this.state.node}
                  close={this.closeWork}
                  idxImg={this.state.imgShow}
                ></OpenWorkPhone>
              </div>
            </div>
          )
        ) : (
          <div />
        )}
        {this.props.showTea ? (
          <div className={styleWork.teaCupContainer}>
            <img
              className={styleWork.teaCup}
              src="/static/eedbbd75e733dd8ba53b6527ba039b09/ee604/tea_cup_Liz_del_Mar.png"
            ></img>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    )
  }
}

export default withTranslation()(workWindow)

// allContentfulWork {
//   edges {
//     node {
//       id
//       enTitle
//       esTitle
//       frTitle
//       images {
//         file {
//           url
//           details {
//             image {
//               height
//               width
//             }
//           }
//         }
//       }
//       frTech
//       esTech
//       enTech
//       year
//     }
//   }
// }
