import React, { Component } from "react"
import Work from "./workWindow"
import styleWork from "./styleWorkWindow.module.css"

import InitWork from "./showInitWork"

class workWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      query: props.dataUrl,
      someActive: false,
      active: false,
      efecto: "slide-left",
    }
    this.randomize = this.randomize.bind(this)
  }

  randomize = () => {
    var numbers = []
    for (let i = 0; i < this.state.data.allContentfulWork.edges.length; i++) {
      numbers.push(i)
    }
    numbers.sort(() => Math.random() - 0.5)
    return numbers
  }

  render() {
    var windows = [
      "/static/d35da5079433adab8ebc8d42552f1cc2/ee604/manchita1_Liz_del_Mar.png",
      "/static/112ad310d402c3fde41883a64c525e78/ee604/manchita2_Liz_del_Mar.png",
    ]
    var bichitos = [
      "/static/b0f65eadbf03dd41b6ed09b552dd0354/ee604/bichitos-trabajos-15.png",
      "/static/acafde9d7b46313b59e759d5ecb0d09c/ee604/bichitos-trabajos-16.png",
      "/static/d2cd856602fee7afac0ba764ba5a04e6/ee604/bichitos-trabajos-17.png",
      "/static/ec688c03b23c579d0f67b705832c2532/ee604/bichitos-trabajos-18.png",
    ]

    const works = []

    var inWin = 0
    var inBi = 0
    var teaCup = true

    var order = this.randomize()
    for (const [
      index,
      value,
    ] of this.state.data.allContentfulWork.edges.entries()) {
      if (index + 1 === this.state.data.allContentfulWork.edges.length) {
        teaCup = false
      }
      works.push(
        <div className={styleWork.Work}>
          <Work
            showTea={teaCup}
            work={this.state.data.allContentfulWork.edges[order[index]]}
            active={false}
            effect={this.state.efecto}
            someActive={this.state.someActive}
            idxImg={0}
            width={100}
            dataUrl={this.props.dataUrl}
            window={windows[inWin]}
            bichito={bichitos[inBi]}
          ></Work>
        </div>
      )
      inWin = (inWin + 1) % windows.length
      inBi = (inBi + 1) % bichitos.length
      teaCup = !teaCup
    }

    return (
      <div className={styleWork.Global} id="workss">
        <InitWork
          data={this.props.data}
          dataUrl={this.props.dataUrl}
        ></InitWork>
        {works}
      </div>
    )
  }
}

export default workWindow
