import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import showroomStyles from "./showroom.module.css"

export default ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "home" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className={showroomStyles.imgContainer}>
      <Img
        className={showroomStyles.ImgShow}
        fluid={data.allFile.edges[1].node.childImageSharp.fluid}
        alt=""
      />
    </div>
  )
}
