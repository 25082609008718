import React, { Component } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import showroomStyles from "./showroom.module.css"
import { withTranslation } from "react-i18next"

import ImgHome from "./ImgShowRoom"
import VideoHome from "./videoShowRoom"

class showroom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      t: props.t,
      i18n: props.i18n,
      isScrolling: false,
      opacity: 1,
      finalKey: "home",
      ecole: "nada",
      query: props.dataUrl,
      keys: [],
      video: props.video,
    }
    this.onClickArrow = this.onClickArrow.bind(this)
    this.getKey = this.getKey.bind(this)
    this.getTextPc = this.getTextPc.bind(this)
    this.getQueryVariable = this.getQueryVariable.bind(this)
    this.theTextPC = {
      home: [
        <h2
          className={showroomStyles.Text}
          id={
            this.state.i18n.languages[0] === "en" ? showroomStyles.UpText : ""
          }
        >
          {this.state.t("showroom.up")}
          <br></br>
          <br></br>
          {this.state.t("showroom.down")}
        </h2>,
      ],
      illustration: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#8D95FF" }}
        >
          {this.state.t("showroom.illustration")}
        </h2>,
      ],
      editorial: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#FF6161" }}
        >
          {this.state.t("showroom.editorial")}
        </h2>,
      ],
      more: [
        <h2 className={showroomStyles.TextIllustration}>
          {this.state.t("showroom.more")}
        </h2>,
      ],
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    var opcity = -(1 / 130) * winScroll + 1
    this.setState({ opacity: opcity })
  }

  getQueryVariable(variable) {
    if (this.state.query !== undefined) {
      var query = this.state.query.substring(1)
      var vars = query.split("&")
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=")
        if (pair[0] == variable) {
          return pair[1]
        }
      }
    }
    return false
  }

  onClickArrow = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    // window.scrollTo(0, winScroll + 500)

    window.scrollTo({
      top: winScroll + 600,
      left: 0,
      behavior: "smooth",
    })
  }

  getTextPc = theKey => {
    var textPC = {
      home: [
        <h2
          className={showroomStyles.Text}
          id={
            this.state.i18n.languages[0] === "en" ? showroomStyles.UpText : ""
          }
        >
          {this.state.t("showroom.up")}
          {/* <br></br>
          <br></br>
          {this.state.t("showroom.down")} */}
        </h2>,
      ],
      illustration: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#8D95FF" }}
        >
          {this.state.t("showroom.illustration")}
        </h2>,
      ],
      editorial: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#FF6161" }}
        >
          {this.state.t("showroom.editorial")}
        </h2>,
      ],
      more: [
        <h2 className={showroomStyles.TextIllustration}>
          {this.state.t("showroom.more")}
        </h2>,
      ],
    }
    return textPC[theKey]
  }

  getTextPhone = theKey => {
    var textPC = {
      home: [
        <h2
          className={showroomStyles.textTitle}
          id={this.state.i18n.languages[0] === "en" ? showroomStyles.en : ""}
        >
          {this.state.t("showroom.up")}
          {/* <br></br>
          <br></br>
          {this.state.t("showroom.down")} */}
        </h2>,
      ],
      illustration: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#8D95FF" }}
        >
          {this.state.t("showroom.illustration")}
        </h2>,
      ],
      editorial: [
        <h2
          className={showroomStyles.TextIllustration}
          style={{ color: "#FF6161" }}
        >
          {this.state.t("showroom.editorial")}
        </h2>,
      ],
      more: [
        <h2 className={showroomStyles.TextIllustration}>
          {this.state.t("showroom.more")}
        </h2>,
      ],
    }
    return textPC[theKey]
  }

  getKey = () => {
    this.state.keys = Object.keys(this.theTextPC)

    for (var i = 0; i < this.state.keys.length; i++) {
      if (this.props.path.includes(this.state.keys[i])) {
        this.state.finalKey = this.state.keys[i]
        break
      }
    }
    return this.state.finalKey
  }

  render() {
    var stilo = {
      opacity: this.state.opacity,
    }

    return (
      <div className={showroomStyles.Global}>
        <div className={showroomStyles.LeftHand}>
          <div className={showroomStyles.TextContainer}>
            {this.getTextPc(this.getKey())}
          </div>
        </div>
        <div className={showroomStyles.RightHand}>
          {this.state.video ? <VideoHome></VideoHome> : <ImgHome></ImgHome>}
          <div className={showroomStyles.textContPhone}>
            {this.getTextPhone(this.getKey())}
            <div style={stilo} onClick={this.onClickArrow}>
              <img
                className={showroomStyles.arrowDown}
                src="/static/b67bc1668f5161c0414d9ea821943be4/73f23/violet_arrow.png"
              ></img>
            </div>
          </div>
        </div>
        <div
          style={stilo}
          onClick={this.onClickArrow}
          className={showroomStyles.arrowCont}
        >
          <img
            className={showroomStyles.arrowDown}
            src="/static/b67bc1668f5161c0414d9ea821943be4/73f23/violet_arrow.png"
          ></img>
        </div>
      </div>
    )
  }
}

export default withTranslation()(showroom)
